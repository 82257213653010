
import Vue from "vue";

export default Vue.extend({
  props: {
    text: {
      type: String,
      default: "Możliwość wprowadzenia zmian w systemie upłynie za:",
    },
    date: {
      type: String,
    },
    startDate: {
      type: String,
    },
    magenta: {
      type: Boolean,
      default: false,
    },
  },
  data(): any {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      passedDate: Math.trunc(new Date(this.date).getTime() / 1000),
      startingDate: Math.trunc(new Date(this.startDate).getTime() / 1000),
    };
  },
  mounted() {
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  computed: {
    seconds(): any {
      return (this.passedDate - this.now) % 60;
    },
    minutes(): any {
      return Math.trunc((this.passedDate - this.now) / 60) % 60;
    },
    hours(): any {
      return Math.trunc((this.passedDate - this.now) / 60 / 60) % 24;
    },
    days(): any {
      return Math.trunc((this.passedDate - this.now) / 60 / 60 / 24);
    },
  },
  methods: {
    languageVarietiesHelper(
      varietyNumber: number,
      singular: string,
      plural: string,
      pluralGenitive: string
    ) {
      if (varietyNumber === 1) {
        return singular;
      } else {
        const modulo10 = varietyNumber % 10;
        const modulo100 = varietyNumber % 100;

        if (
          modulo10 > 4 ||
          modulo10 < 2 ||
          (modulo100 < 15 && modulo100 > 11)
        ) {
          return pluralGenitive;
        } else {
          return plural;
        }
      }
    },
  },
  watch: {
    date: function () {
      this.passedDate = Math.trunc(new Date(this.date).getTime() / 1000);
    },
    startDate: function () {
      this.startingDate = Math.trunc(new Date(this.startDate).getTime() / 1000);
    },
  },
});
